import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/index",
  //   name: "index",
  //   component: () => import("../views/index.vue"),
  // },
  // {
  //   path: "/",
  //   name: "default",
  //   component: () => import("../views/SandLandSEE.vue"),
  // },
  // {
  //   path: "/login",
  //   name: "login",
  //   component: () => import("../views/login.vue"),
  // },
  // {
  //   path: "/index2",
  //   name: "index2",
  //   component: () => import("../views/index2.vue"),
  // },
  // {
  //   path: "/index3",
  //   name: "index3",
  //   component: () => import("../views/index3.vue"),
  // },
  // {
  //   path: "/index4",
  //   name: "index4",
  //   component: () => import("../views/index4.vue"),
  // },
  // {
  //   path: "/index5",
  //   name: "index5",
  //   component: () => import("../views/index5.vue"),
  // },
  // {
  //   path: "/index6",
  //   name: "index6",
  //   component: () => import("../views/index6.vue"),
  // },
  // {
  //   path: "/mainmap",
  //   name: "mainmap",
  //   component: () => import("../views/components/mainMap.vue"),
  //   hidden: true,
  // },
  // {
  //   path: "/index7",
  //   name: "index7",
  //   component: () => import("../views/index7.vue"),
  // },
  // {
  //   path: "/lsindex",
  //   name: "lsindex",
  //   component: () => import("../views/lsindex.vue"),
  // },
  // {
  //   path: "/widthfitindex",
  //   name: "widthfitindex",
  //   component: () => import("../views/widthFitIndex.vue"),
  // },
  // {
  //   path: "/scoreandlevelmapindex",
  //   name: "scoreandlevelmapindex",
  //   component: () => import("../views/ScoreAndLevelMapIndex.vue"),
  // },
  {
    path: "/slsee",
    name: "seemapindex",
    component: () => import("../views/SandLandSEE.vue"),
  },
  // {
  //   path: "/index_temp",
  //   name: "index_temp",
  //   component: () => import("../views/index_temp.vue"),
  // },
  // {
  //   path: "/h5",
  //   name: "h5",
  //   component: () => import("../views/h5.vue"),
  // },
];
const router = new VueRouter({
  routes,
});

export default router;
